import { Button } from "antd"
import { Content } from "antd/lib/layout/layout"
import { Link } from "gatsby"
import React from "react"

export default function ThankYou() {
  return (
    <div>
      <Content>
        <div
          className="site-layout-background"
          style={{
            padding: 24,
            minHeight: 360,
          }}
        >
          <div className="flex flex-row gap-[7px] justify-center items-center m-16">
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="14"
                cy="14"
                r="13"
                fill="white"
                stroke="#EA6D69"
                strokeWidth="2"
              />
              <path
                d="M6.2998 14L11.1998 18.9L20.9998 9.09998"
                stroke="#EA6D69"
                strokeWidth="3"
              />
            </svg>
            <div className="text-[22px] font-bold text-rose-400">
              ご登録ありがとうございます。
            </div>
          </div>
          <div className="flex justify-center">
            <Link to="/">
              <Button
                type="primary"
                style={{ width: "320px", marginTop: "4rem" }}
              >
                TOPに戻る
              </Button>
            </Link>
          </div>
        </div>
      </Content>
    </div>
  )
}
