import React from "react"
import Topbar from "../../../../components/organisms/topbar"
import CustomLayout from "../../../../Layouts/Layout"
import ThankYou from "./ThankYou"

function index() {
  return (
    <div>
      <Topbar />
      <CustomLayout selectedPage={<ThankYou />} />
    </div>
  )
}

export default index
